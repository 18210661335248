<template>
  <div>
    <div v-if="getShowLoader">
      <Loader/>
    </div>
    <div v-else class="authLayout_screens">
      <div class="spiral-row">
        <img src="@/assets/images/svg/Spiral_Symbol_Home_Screen.svg" class="spiral">
      </div>
      <div class="zoomi_logo_group">
        <p class="heading text">
          Welcome To
        </p>
        <img src="@/assets/images/svg/Zoomi_logo.svg" class="zoomi_logo_group-logo">
        <p><img src="@/assets/images/svg/sensory.svg" class="zoomi_logo_group-img_sensory"></p>
      </div>
      <div class="form">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import Loader from '../../components/socialvue/breadcrumbs/Loader'
// import logo from '../../assets/images/logo-white.png'
import { socialvue } from '../../config/pluginInit'
// import bgImage from '../../assets/images/login/login-2.jpg'
export default {
  name: 'AuthLayout1',
  components: {
    Loader
  },
  data () {
    return {
      // slickOptions: {
      //   slidesToShow: 1,
      //   arrows: false
      // },
      // logo: logo,
      // bgImageURL: bgImage
    }
  },
  computed: {
    ...mapGetters([
      'getShowLoader'
    ])
  },
  methods: {
    ...mapMutations([
      'setShowLoader'
    ])
  },
  mounted () {
    socialvue.index()
  },
  beforeDestroy () {
    this.setShowLoader(false)
  }
}
</script>
<style lang="scss" scoped>
@import url("../../assets/css/custom.css");
@import url("../../assets/css/PriceSlider.css");

.authLayout_screens {
  background-color: #0b1113;
  height: 100vh;
  width: 100vw;
}
.spiral {
  &-row {
  width: 100vw;
  text-align: center;
  }
  margin-top: 3.5vh;
  width: 40vw;
  height: 25vh;
}
.heading {
  font-size: 2vw;
  font-weight: 100 !important;
}
.zoomi_logo_group {
  position: absolute;
  width: 100%;
  z-index: 11;
  text-align: center;
  &-logo {
  width: 16.5%;
  margin-top: -2.5vh;
  }
  &-img_sensory {
    margin: -0.5vh 0 0 0.5vw;
    width: 17.5%;
  }
}
.form {
  margin-top: 21vh;
  width: 100vw;
}
.text {
  color: white !important;
}
</style>
