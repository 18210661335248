<template>
  <div class="loader">
  </div>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
.loader {
  border: 16px solid #3498db;
  border-radius: 50%;
  border-top: 16px solid #f3f3f3;
  width: 50%;
  height: 50%;
  padding: 60px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
